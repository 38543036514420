





























































































import { Component, Vue } from "vue-property-decorator";
import {
  // activityAdd,
  // activityStatus,
  // activityEdit,
  myfollowerList,
} from "@/apis";
import { parseTime } from "@/utils";
//import Edit from "@/views/ActionCenter/ActivityManagement/module/Edit.vue";
@Component({
  components: {},
  filters: {
    parseTimeFilter(time: any, str: string) {
      if (time) {
        return parseTime(time, str);
      } else {
        return "-";
      }
    },
  },
})
export default class MyPatient extends Vue {
  group: Array<any> = [];
  formList = {
    key_word: "",
  };
  // 分页
  form = {
    cur_page: 1,
    page_size: 10,
  };
  totalSize = 0;
  created() {
    this.requestList();
  }
  handleSizeChange(val: number) {
    console.log(`每页 ${val} 条`);
    this.form.cur_page = 1;
    this.form.page_size = val;
    this.requestList();
  }

  handleCurrentChange(val: number) {
    console.log(`当前页: ${val}`);
    this.form.cur_page = val;
    this.requestList();
  }

  toDetail(v: any) {
    this.$router.push({
      path: "/onlineconsultroom/mypatient/detail",
      query: { id: v.patient_id },
    });
  }

  //重置
  searchReset() {
    this.formList = {
      key_word: "",
    };
  }

  searchButton() {
    this.requestList();
  }

  async requestList() {
    if (!this.formList.key_word) (this.formList as any).key_word = null;
    const result = await myfollowerList({ ...this.formList, ...this.form });
    if (result.success) {
      this.group = result.data;
      // this.form.cur_page = 1;
      this.totalSize = result.page.total_size;
    } else {
      this.$message.success("搜索失败");
    }
  }
}
